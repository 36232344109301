import React from "react";
import { motion } from "framer-motion";

import { Bio } from "../data/constants";

const SocialIcons = (props) => {
  return (
    <div className="icon-container">
      {Bio.socialConnect.map((social) => {
        return (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: [0, 1, 1.5, 1] }}
            transition={{ type: "spring", duration: 1, delay: 1 }}
          >
            <a target="_blank" rel="noreferrer" href={social.link}>
              <social.Icon size={25} color="white" />
            </a>
          </motion.div>
        );
      })}

      <motion.span
        className="line-container"
        color={props.theme}
        initial={{
          height: 0,
        }}
        animate={{
          height: "8rem",
        }}
        transition={{
          type: "spring",
          duration: 1,
          delay: 0.8,
        }}
      ></motion.span>
    </div>
  );
};

export default SocialIcons;
