import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";

const ExperienceCard = ({ experience }) => {
  return (
    <VerticalTimelineElement
      icon={
        <img
          width="100%"
          height="100%"
          alt={experience.school}
          style={{ borderRadius: "50%", objectFit: "cover" }}
          src={experience.img}
        />
      }
      contentStyle={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        background: "#1d1836",
        color: "#fff",
        boxShadow: "rgba(23, 92, 230, 0.15) 0px 4px 24px",
        backgroundColor: "rgba(17, 25, 40, 0.83)",
        border: "1px solid rgba(255, 255, 255, 0.125)",
        borderRadius: "6px",
      }}
      contentArrowStyle={{
        borderRight: "7px solid  rgba(255, 255, 255, 0.3)",
      }}
    >
      <div className="experience-top-container">
        <img
          src={experience.img}
          className="experience-top-logo-image"
          alt="company-logo"
        />
        <div className="experience-top-section">
          <div className="experience-top-role"> {experience.role}</div>
          <div className="experience-top-company">{experience.company}</div>
          <div className="experience-top-date">{experience.date}</div>
        </div>
      </div>

      <div className="experience-description ">
        {/* {experience?.desc && (
            <div className="experience-description-text">{experience?.desc}</div>
          )} */}
        {/* <br /> */}
        <div className="experience-skill-container">
          <b>Skills</b>
          <div className="ex-tag-container">
            {experience?.skills?.map((skill, index) => (
              <div className="ex-tag" key={index}>
                {skill}
              </div>
            ))}
          </div>
          {/* <div className="experience-skill-wrapper">
            {experience?.skills?.map((skill, index) => (
              <div className="experience-skill" key={index}>
                • {skill}
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </VerticalTimelineElement>
  );
};

export default ExperienceCard;
