import React from "react";
import { Tilt } from "react-tilt";

const ServiceCard = ({ title, Icon }) => {
  return (
    <Tilt>
      <div className="service-card ">
        <div className="text-center">
          <Icon color="white" size={100} />
          <div className="service-card-title mgTop10 ">{title}</div>
        </div>
      </div>
    </Tilt>
  );
};

export default ServiceCard;
