import React from "react";

const ContentLayout = ({ content, className = "", ...rest }) => {
  return (
    <p className={`content  ${className}`} {...rest}>
      {content}
    </p>
  );
};

export default ContentLayout;
