import React from "react";

const SectionHeader = ({ title = "", description = "" }) => {
  return (
    <div className="section-header-container">
      <div className="section-header-description">{description}</div>
      <div className="section-header-title">{title}</div>
    </div>
  );
};

export default SectionHeader;
