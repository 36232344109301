import About from "../components/sections/About";
import Contact from "../components/sections/Contact";
import Experience from "../components/sections/Experience";
import Hero from "../components/sections/Hero";
import Projects from "../components/sections/Projects";
import Roles from "../components/sections/Roles";
import Skills from "../components/sections/Skills";
import SectionLayout from "../layouts/Section";

function Home() {
  return (
    <>
      <Hero />
      <SectionLayout className="section-wrapper">
        <Roles />
      </SectionLayout>
      <Skills />

      <SectionLayout className="section-wrapper">
        <About />
      </SectionLayout>

      <Experience />

      <SectionLayout className="section-wrapper">
        <Projects />
      </SectionLayout>
      <Contact />
    </>
  );
}

export default Home;
