import SectionHeader from "../components/SectionHeader";
import ColLayout from "../layouts/Col";
import ContainerLayout from "../layouts/Container";
import RowLayout from "../layouts/Row";
import SectionLayout from "../layouts/Section";

function BlankPage() {
  return (
    <>
      <SectionLayout>
        <ContainerLayout>
          <RowLayout>
            <ColLayout>
              <SectionHeader
                title="Blank Page"
                description="Some of my personal article."
              />
            </ColLayout>
          </RowLayout>
        </ContainerLayout>
      </SectionLayout>
    </>
  );
}

export default BlankPage;
