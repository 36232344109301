import { BrowserRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Footer from "./components/sections/Footer";
import SocialIcons from "./components/SocailIcons";
import Navbar from "./components/Navbar";
import Application from "./Application";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/global.css";

// darkTheme
function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="main-container">
        <AnimatePresence>
          <Application />
        </AnimatePresence>
        <Footer />
        <SocialIcons color="light" />
      </div>
    </BrowserRouter>
  );
}

export default App;
