import React from "react";

const SectionLayout = ({ children, className = "", ...rest }) => {
  return (
    <section className={`section-container ${className}`} {...rest}>
      {children}
    </section>
  );
};

export default SectionLayout;
