import React from "react";
import ColLayout from "../layouts/Col";
import StyledStarsCanvas from "../components/canvas/Stars";
import SectionLayout from "../layouts/Section";
import { Link } from "react-router-dom";
import NotFoundImage from "./../assets/Images/pageNotFound.png";

function PageNotFound() {
  return (
    <>
      <StyledStarsCanvas />
      <SectionLayout className=" not-found-container">
        <ColLayout className="image-container">
          <img
            src={NotFoundImage}
            className=" not-found-image"
            alt="not found"
          />
        </ColLayout>
        <div className="text-container  " style={{ zIndex: 1000 }}>
          <h1 style={{ color: "white" }}>404 ERROR</h1>
          <h2>{`Couldn't launch :(`}</h2>
          <h3>Page Not Found - lets take you</h3>
          <h3>
            <Link to={"/"}>BACK</Link>
          </h3>
        </div>
      </SectionLayout>
    </>
  );
}

export default PageNotFound;
