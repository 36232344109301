import React from "react";
import SectionHeader from "../SectionHeader";
import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";
import ContentLayout from "../../layouts/ContentLayout";
import { Fade } from "react-reveal";

import { ProjectWork } from "../../data/constants";
import { darkTheme } from "../../utils/Themes";

const skill = ProjectWork[0];

const ProjectDescriptionLayout = () => {
  return (
    <div className="project-detail-descrption-container">
      <Fade right duration={1000}>
        <h1
          className="project-detail-descrption-heading"
          style={{ color: darkTheme.text }}
        >
          {skill.title}
        </h1>
      </Fade>

      <Fade right duration={2000}>
        {skill.description.map((skillSentence, index) => {
          return <ContentLayout key={index} content={skillSentence} />;
        })}
      </Fade>
    </div>
  );
};

const ProjectImgLayout = () => {
  return (
    <Fade left duration={2000}>
      <div className="project-detail-img-container">
        <skill.Svg theme={darkTheme} />
      </div>
    </Fade>
  );
};

const Projects = () => {
  return (
    <div id="projects" className="project-main-container">
      <ContainerLayout>
        <RowLayout>
          <ColLayout>
            <SectionHeader title="Projects" description="My Work" />
          </ColLayout>
        </RowLayout>
        <RowLayout>
          <div className="project-detail-main-container">
            <ColLayout>
              <ProjectImgLayout />
            </ColLayout>
            <ColLayout>
              <ProjectDescriptionLayout />
            </ColLayout>
          </div>
        </RowLayout>

        {/* <RowLayout>
          <ColLayout>
            <div className=" project-more-container text-center">
              <PrimaryButton
                onClick={() => {
                  console.log("clcik");
                }}
                title={"View More"}
              />
            </div>
          </ColLayout>
        </RowLayout> */}
      </ContainerLayout>
    </div>
  );
};

export default Projects;
