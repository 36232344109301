import React from "react";
import { Fade } from "react-reveal";

import SectionHeader from "../SectionHeader";
import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";

import { Bio } from "../../data/constants";

const Roles = () => {
  return (
    <ContainerLayout id="Roles">
      <RowLayout>
        <ColLayout>
          <SectionHeader
            title="Role & Responsibility"
            description="Here's what I do"
          />
        </ColLayout>
      </RowLayout>
      <RowLayout>
        <ColLayout>
          <Fade right duration={2000}>
            {Bio.overView.map((skillSentence, index) => {
              return (
                <p
                  key={index}
                  className="subTitle skills-text"
                  style={{ color: "#8D8D8D" }}
                >
                  {skillSentence}
                </p>
              );
            })}
          </Fade>
        </ColLayout>
      </RowLayout>
    </ContainerLayout>
  );
};

export default Roles;
