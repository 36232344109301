import React from "react";
const ColLayout = ({ children, className = "", ...rest }) => {
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
};

export default ColLayout;
