import React from "react";
import { Tilt } from "react-tilt";

import SectionLayout from "../../layouts/Section";
import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";
import SectionHeader from "../SectionHeader";

import { Bio } from "../../data/constants";
import StyledStarsCanvas from "../canvas/Stars";

const Skills = () => {
  return (
    <SectionLayout className="skill-main-section" id="skills">
      <StyledStarsCanvas />
      <ContainerLayout>
        <RowLayout>
          <ColLayout>
            <SectionHeader title="Skills" description="" />
          </ColLayout>
        </RowLayout>
        <RowLayout>
          <ColLayout>
            <div className="skill-container">
              {Bio.skillset.map((skill, i) => {
                return (
                  <Tilt key={skill.id}>
                    <div className="skill-section">
                      <skill.ImageComponent color="white" size={80} />
                    </div>
                  </Tilt>
                );
              })}
            </div>
          </ColLayout>
        </RowLayout>
      </ContainerLayout>
    </SectionLayout>
  );
};

export default Skills;
