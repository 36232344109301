import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import BlogPage from "./pages/Blog";
import ProjectPage from "./pages/Project";
import PageNotFound from "./pages/PageNotFound";
import BlankPage from "./pages/Blank";

function Application() {
  return (
    <>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route exact path="/projects" element={<ProjectPage />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route exact path="/blank" element={<BlankPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default Application;
