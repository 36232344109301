import React from "react";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

import { Bio } from "../../data/constants";

import {
  headContainerAnimation,
  headContentAnimation,
  headTextAnimation,
} from "../../utils/motion";

import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";
import { PrimaryButton } from "../../layouts/Button";

import StyledStarsCanvas from "./../canvas/Stars";
import { BannerAnimation } from "../../utils/AllSvgs";

const Hero = () => {
  return (
    <div className="section-banner" id="overviewMe">
      <StyledStarsCanvas />
      <ContainerLayout>
        <RowLayout>
          <ColLayout className="col-md-6 col-sm-12 col-xs-12 ">
            <div className="section-banner-container">
              <motion.div {...headContainerAnimation}>
                <motion.div {...headTextAnimation}>
                  <div className="title">Hi, I am {Bio.name}</div>
                  <div className="text-loop">
                    I am a
                    <span className="">
                      <Typewriter
                        options={{
                          strings: Bio.roles,
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </span>
                  </div>
                </motion.div>

                <motion.div {...headContentAnimation}>
                  <div className="description">{Bio.shortDescription}</div>
                </motion.div>
                <PrimaryButton
                  title="Checkout my Work "
                  onClick={() => {
                    window.location.href = "/#projects";
                  }}
                />
              </motion.div>
            </div>
          </ColLayout>

          <ColLayout className="col-md-6 col-sm-12 d-none d-md-block">
            {/* <div className="banner-animation"> */}
            <BannerAnimation />
            {/* </div> */}
          </ColLayout>
        </RowLayout>
      </ContainerLayout>
    </div>
  );
};

export default Hero;
