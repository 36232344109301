import React from "react";

import { VerticalTimeline } from "react-vertical-timeline-component";

import ExperienceCard from "../cards/ExperienceCard";
import SectionHeader from "../SectionHeader";
import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";

import { experiences } from "../../data/constants";

import "react-vertical-timeline-component/style.min.css";
import SectionLayout from "../../layouts/Section";

const Experience = () => {
  return (
    <SectionLayout className="experience-main-container" id="experience">
      <ContainerLayout>
        <RowLayout>
          <ColLayout>
            <SectionHeader
              title="Work Experience"
              description="What I have done so far"
            />
          </ColLayout>
        </RowLayout>
        <RowLayout>
          <ColLayout>
            <VerticalTimeline animate={true}>
              {experiences.map((experience, index) => (
                <ExperienceCard
                  key={`experience-${index}`}
                  experience={experience}
                />
              ))}
            </VerticalTimeline>
          </ColLayout>
        </RowLayout>
      </ContainerLayout>
    </SectionLayout>
  );
};

export default Experience;
