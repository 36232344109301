import React from "react";

const RowLayout = ({ children, className = "", ...rest }) => {
  return (
    <div className={`row ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default RowLayout;
