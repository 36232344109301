import React from "react";

import SectionHeader from "../SectionHeader";
import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";

import { Bio } from "../../data/constants";
import ContactCard from "../cards/ContactCard";
import SectionLayout from "../../layouts/Section";
import StarCanvas from "./../canvas/Stars";

const Contact = () => {
  return (
    <SectionLayout className="contact-section" id="contact">
      <StarCanvas />
      <ContainerLayout>
        <RowLayout>
          <ColLayout>
            <SectionHeader
              title="Connect"
              description="Convert Ideas into incredible Digital Product"
            />
          </ColLayout>
        </RowLayout>
        <RowLayout>
          {Bio.contactCard.map((card) => {
            return (
              <ColLayout
                key={card.id}
                className="col-md-4 col-sm-6 col-xs-12 contact-card-container"
              >
                <ContactCard
                  title={card.title}
                  detail={card.detail}
                  Icon={card.Icon}
                  key={card.id}
                />
              </ColLayout>
            );
          })}
        </RowLayout>
      </ContainerLayout>
    </SectionLayout>
  );
};

export default Contact;
