import React from "react";

const ContactCard = ({ title, detail, Icon }) => {
  return (
    <div className="contact-card-section">
      <div className="contact-card-main-container">
        <Icon color="white" size={50} />
        <div className="contact-card-detail-container">
          <div className="card-title">{title}</div>
          <div clas className="card-detail grade">
            {detail}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
